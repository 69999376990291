<template>
  <div>
    <Navbar />

    <section class="px-4 lg:px-2 py-8 2xl:py-10 md:px-12 flex-1">
      <div
        class="flex flex-col space-y-4 lg:gap-4 items-start justify-between mb-8 md:flex-row"
      >
        <div
          class="flex flex-col xl:flex-row space-y-4 lg:space-y-0 lg:gap-4 xl:gap-8"
        >
          <div
            class="flex items-end justify-between 2xl:justify-start space-x-4 gap-4"
          >
            <h1 class="text-2xl 2xl:text-4xl font-semibold">
              {{ name }}
            </h1>
          </div>

          <ul class="flex gap-4 text-sm xl:text-base text-slate-500">
            <li v-if="date" class="flex items-center space-x-2 lg:gap-2">
              <CalendarIcon class="hidden w-5 h-5 lg:flex" />
              Inizio: {{ dateTime(date) }}
            </li>
            <li v-if="dateEnd" class="flex items-center space-x-2 lg:gap-2">
              <CalendarIcon class="hidden w-5 h-5 lg:flex" />
              Fine: {{ dateTime(dateEnd) }}
            </li>
          </ul>
        </div>

        <div class="flex justify-end space-x-2 lg:space-x-0">
          <span class="btn" @click="resyncSession">{{ resyncing ? 'Cariamento...' : 'Resync' }}</span>
          <router-link
            :to="{
              name: 'SessionEdit',
              params: { id: id, name: name },
            }"
            class="btn btn--red px-3 py-1"
          >
            <PencilIcon class="w-5 h-5" />
          </router-link>
        </div>
      </div>

      <section class="lg:hidden mb-2">
        <button
          @click="
            tab1 = true;
            tab2 = false;
          "
          class="btnTab1 p-3 rounded transition duration-300"
          :class="tab1 ? 'bg-sky-500' : ''"
        >
          <h2 class="block lg:text-xl 2xl:text-2xl font-semibold leading-none">
            Brani scelti
          </h2>
        </button>

        <button
          @click="
            tab1 = false;
            tab2 = true;
          "
          class="btnTab2 p-3 rounded transition duration-300"
          :class="tab2 ? 'bg-sky-500' : ''"
        >
          <h2 class="block lg:text-xl 2xl:text-2xl font-semibold leading-none">
            Brani in coda
          </h2>
        </button>
      </section>

      <div class="grid lg:grid-cols-2 gap-4 2xl:gap-10">
        <section
          class="tab1 bg-slate-800 lg:bg-transparent p-3 rounded lg:p-0 lg:block transition duration-300"
          :class="[tab1 ? 'block' : 'hidden']"
        >
          <h2
            class="hidden lg:block text-xl 2xl:text-2xl font-semibold mb-3 leading-none"
          >
            Brani scelti
          </h2>

          <table class="table-auto w-full">
            <thead
              class="hidden lg:table-header-group text-xs uppercase tracking-wider opacity-50 leading-none border-b border-white border-opacity-10 font-normal text-left"
            >
              <tr>
                <th class="py-4">Titolo</th>
                <th class="hidden lg:table-cell py-4">Album</th>
                <th class="hidden xl:table-cell py-4">Scelto da</th>
                <th class="hidden xl:table-cell py-4">&nbsp;</th>
                <th class="hidden xl:table-cell py-4">&nbsp;</th>
              </tr>
            </thead>

            <tbody v-if="songPicks.length">
              <tr
                v-for="pick in songPicks"
                :key="pick.id"
                class="flex flex-col py-4 gap-2 lg:table-row text-sm relative"
              >
                <td class="flex lg:p-2 lg:pl-0 lg:gap-2">
                  <img
                    :src="pick.song.image"
                    :alt="pick.song.name"
                    class="hidden xl:block w-12 h-12 object-cover rounded-sm"
                  />

                  <div class="lg:p-2">
                    <h3 v-if="pick.song.name" class="font-semibold">
                      {{ pick.song.name }}
                    </h3>
                    <p v-if="pick.song.artist" class="text-sm opacity-60">
                      {{ pick.song.artist }}
                    </p>
                  </div>
                </td>

                <td class="hidden xl:table-cell opacity-60 lg:p-2">
                  {{ pick.song.album }}
                </td>

                <td class="text-sm opacity-60 lg:p-2">
                  <span class="lg:hidden">Scelto da </span>
                  <strong>{{ pick.name }}</strong>
                </td>

                <td class="absolute lg:static right-0">
                  <button
                    @click="playSong(pick.id)"
                    class="flex flex-col items-center gap-1"
                    :disabled="!this.fetchingPicks"
                  >
                    <ArrowNarrowRightIcon
                      class="rounded-full border border-white border-opacity-10 lg:hidden w-8 h-8 p-1 hover:bg-sky-500 transition"
                    />
                    <span
                      class="uppercase text-xs font-bold kerning-2 lg:text-sky-500 lg:hover:text-red-500 transition duration-300"
                      >Suona</span
                    >
                  </button>
                </td>
                <td class="absolute lg:static right-0">
                  <button
                    @click="deleteSongPick(pick.id)"
                    class="flex flex-col items-center gap-1"
                    :disabled="!this.fetchingPicks"
                  >
                    <ArrowNarrowRightIcon
                      class="rounded-full border border-white border-opacity-10 lg:hidden w-8 h-8 p-1 hover:bg-sky-500 transition"
                    />
                    <TrashIcon class="w-5 h-5 lg:text-sky-500 lg:hover:text-red-500 transition"></TrashIcon>
                  </button>
                </td>
              </tr>
            </tbody>
            <div v-else class="lg:px-3">
              Nessuna canzone scelta per questa serata.
            </div>
          </table>
        </section>

        <section
          class="tab1 bg-slate-800 lg:bg-transparent p-3 rounded lg:p-0 lg:block transition duration-300"
          :class="[tab2 ? 'block' : 'hidden']"
        >
          <h2
            class="hidden lg:block text-xl 2xl:text-2xl font-semibold mb-3 leading-none"
          >
            Brani in coda
          </h2>

          <table class="table-auto w-full">
            <thead
              class="hidden lg:table-header-group text-xs uppercase tracking-wider opacity-50 leading-none border-b border-white border-opacity-10 font-normal text-left"
            >
              <tr>
                <th class="py-4">Titolo</th>
                <th class="hidden lg:table-cell py-4">Album</th>
                <th class="hidden xl:table-cell py-4">Scelto da</th>
                <th class="hidden xl:table-cell py-4">&nbsp;</th>
              </tr>
            </thead>

            <tbody v-if="inQueueSongPicks.length">
              <tr
                v-for="pick in inQueueSongPicks.slice().reverse()"
                :key="pick.id"
                class="text-sm relative"
              >
                <td class="flex gap-2 py-4 lg:p-2 lg:pl-0">
                  <img
                    :src="pick.song.image"
                    :alt="pick.song.name"
                    class="hidden xl:block w-12 h-12 object-cover rounded-sm"
                  />

                  <div class="lg:p-2">
                    <h3 v-if="pick.song.name" class="font-semibold">
                      {{ pick.song.name }}
                    </h3>
                    <p v-if="pick.song.artist" class="text-sm opacity-60">
                      {{ pick.song.artist }}
                    </p>
                  </div>
                </td>

                <td class="hidden xl:table-cell opacity-60 lg:p-2">
                  {{ pick.song.album }}
                </td>

                <td class="text-sm opacity-60 py-4 lg:p-2">
                  <span class="lg:hidden">Scelto da </span>
                  <strong>{{ pick.name }}</strong>
                </td>

                <td class="absolute lg:static right-0 lg:p-2">
                  <button
                    @click="unplaySong(pick.id)"
                    class="flex flex-col items-center gap-1"
                    :disabled="!this.fetchingPicks"
                  >
                    <XIcon
                      class="rounded-full border border-white border-opacity-10 lg:hidden w-8 h-8 p-1 hover:bg-sky-500 transition"
                    />
                    <span
                      class="uppercase text-xs font-bold kerning-2 lg:text-sky-500 lg:hover:text-red-500 transition duration-300"
                      >Rimuovi</span
                    >
                  </button>
                </td>
              </tr>
            </tbody>
            <div v-else class="lg:px-3">
              Nessuna canzone suonata per questa serata.
            </div>
          </table>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import { getClient } from "@/includes/api";
import moment from "moment";
moment.locale("it");

import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";

import {
  CalendarIcon,
  PencilIcon,
  ArrowNarrowRightIcon,
  XIcon,
  TrashIcon,
} from "@heroicons/vue/solid";

import IconChevronDown from "@/components/icons/IconChevronDown.vue";

export default {
  props: ["id", "name", "date", "dateEnd", "code"],
  components: {
    Footer,
    Navbar,
    CalendarIcon,
    PencilIcon,
    ArrowNarrowRightIcon,
    XIcon,
    IconChevronDown,
    TrashIcon,
  },
  data() {
    return {
      playlists: [],
      songPicks: [],
      inQueueSongPicks: [],
      fetchingPicks: false,
      tab1: true,
      tab2: false,
      resyncing: false,
    };
  },
  methods: {
    dateTime(value) {
      return moment(value).format("DD MMMM YYYY HH:mm");
    },
    async resyncSession() {
      if (!window.confirm("Sei sicuro di voler risincronizzare la playlist della serata?")) {
        return;
      }

      try {
        let apiClient = getClient();
        this.resyncing = true;
        await apiClient.resyncSession(this.id);
        this.resyncing = false;
      } catch(error) {
        console.log(error.message);
        this.error = error.message;
      }
    },
    async playSong(id) {
      try {
        let apiClient = getClient();

        let playedSong = await apiClient.playSong(id);
        let pickKey = this.songPicks.findIndex((songPick) => {
          return songPick.id === playedSong.id;
        });

        await this.fetchSongPicks();
      } catch (error) {
        console.log(error.message);
        this.error = error.message;
      }
    },
    async unplaySong(id) {
      try {
        let apiClient = getClient();

        let playedSong = await apiClient.unplaySong(id);
        let pickKey = this.songPicks.findIndex((songPick) => {
          return songPick.id === playedSong.id;
        });

        await this.fetchSongPicks();
      } catch (error) {
        console.log(error.message);
        this.error = error.message;
      }
    },
    async deleteSongPick(id) {
      try {
        const apiClient = getClient();
        await apiClient.deleteSongPick(id);
        await this.fetchSongPicks();
     } catch (error) {
        console.log(error.message);
        this.error = error.message;
     }
    },
    isPlayedSong(pick) {
      return pick.status === "in_queue";
    },
    async fetchSongPicks() {
      this.fetchingPicks = false;
      try {
        let id = this.$props.id;
        let apiClient = getClient();
        let songPicks = await apiClient.findSongPicks(id, {
          status: "picked",
        });
        if (Array.isArray(songPicks)) {
          this.songPicks = songPicks;
        }

        let inQueueSongPicks = await apiClient.findSongPicks(id, {
          status: "in_queue",
          order: "order",
        });

        if (Array.isArray(inQueueSongPicks)) {
          this.inQueueSongPicks = inQueueSongPicks;
        }
        this.fetchingPicks = true;
      } catch (e) {
        console.dir(e);
        this.fetchingPicks = true;
      }
    },
  },
  async mounted() {
    const details = document.querySelectorAll("details");
    details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", () => {
        details.forEach((detail) => {
          if (detail !== targetDetail) {
            detail.removeAttribute("open");
          }
        });
      });
    });

    try {
      let id = this.$props.id;
      const apiClient = getClient();
      let playlists = await apiClient.getSessionPlaylists(id);
      if (Array.isArray(playlists)) {
        this.playlists = playlists;
      }
      setInterval(() => {
        if (!this.fetchingPicks) {
          this.fetchSongPicks();
        }
      }, 2000);
    } catch (err) {
      console.error(err);
    }
  },
};
</script>

<style scoped>
details[open] svg {
  transform: rotate(180deg);
  transition: 0.3s;
}
</style>
